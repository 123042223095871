<template>
  <section>
    <h2>Diagnostiek</h2>
    <p>Psychodiagnostiek is gericht op het verwerven en verwerken van informatie om tot
    oplossingen te komen. Het verkrijgen van de informatie dient zo professioneel mogelijk
     te gebeuren door zowel verschillende onderzoeksmethoden, bronnen te gebruiken als
      de problematiek vanuit diverse omgevingen / situaties te bekijken.</p>
    <p>In de praktijk bestaat diagnostiek vaak uit drie delen: het intakegesprek, het
    onderzoek en het adviesgesprek.</p>
    <h3>Intakegesprek</h3>
    <p>Tijdens een intakegesprek wordt er samen met ouders vastgesteld wat voor hulp
     er voor hun kind of voor hun situatie nodig is. Soms is één gesprek al voldoende om
     ouders voldoende inzicht en handvatten te geven om zelf verder te gaan. Een
     intakegesprek neemt een uur in
   beslag.</p>
    <h3>Diagnostisch onderzoek</h3>
    <p>Aan de hand van de hulpvragen van ouders wordt afgesproken
     hoe het onderzoekstraject eruit gaat zien. In dit traject kunnen zowel de cognitieve,
      sociaal-emotionele mogelijkheden van het kind als de omgeving van het kind
       bekeken worden. Vaak wordt er gestart met een intelligentieonderzoek. Dit is
     een uitgebreid onderzoek dat ongeveer drie uur in beslag neemt. Naast individuele
   testen en gesprekken met het kind bestaat een onderzoek uit observaties, gesprekken
    met betrokkenen en het afnamen van vragenlijsten. Dit alles om een zo volledig
  mogelijk beeld te krijgen van wat er speelt en ook om zicht te krijgen welke
   hulp wenselijk is. Een breed onderzoek wordt meestal gespreid over een aantal weken.</p>
    <h3>Adviesgesprek</h3>
    <p>Alle onderzoeksresultaten worden uitgewerkt in een verslag. Dit onderzoeksverslag
     wordt naar ouders gestuurd. Daarna worden ouders uitgenodigd om het verslag en
   de resultaten van onderzoek te bespreken. In een adviesgesprek wordt de uitslag en
 de adviezen voor hulp toegelicht. Meestal duurt een adviesgesprek één uur.</p>
  </section>
</template>
<style scoped lang="scss">
section {
  text-align:left;
  padding: 10px;
}
</style>
