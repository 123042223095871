<template>
  <section>
    <h2>Behandeling</h2>
    <p><i>Op het Lint Orthopedagogiek</i> geeft behandeling op maat: Naar aanleiding
     van wat er nodig is,
     wordt hulp ingezet. Dit kan een vast traject inhouden. Zo bestaat een
     <i>Beter bij de Les-traject</i>
   uit 20-25 sessie van 45 minuten. Er kan ook sprake zijn van procesdiagnostiek: Hierbij
    wordt hulp gestart
    en al gaande bijgesteld. Er vindt na een aantal sessies een evaluatie plaats
     en aan de hand daarvan wordt vastgesteld wat er precies nodig is. De behandelingen kunnen op
   school of op de praktijk plaatsvinden.</p>

   <p>Voor de volgende evidence-based behandelingen kunt u bij
    mij terecht:
    <ul>
      <li>Beter bij de Les</li>
      <li>COMET</li>
      <li>faalangstreductietraining</li>
      <li>dyslexiebehandeling</li>
      <li>psycho-educatie</li>
    </ul>
  .</p>
  </section>
</template>

<style scoped lang="scss">

section {
  text-align:left;
  padding: 10px;
}

</style>
