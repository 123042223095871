<template>
  <div class="aanbod">
    <h1>This is an aanbod page</h1>
  </div>
</template>

<style scoped lang="scss">
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

section {
  text-align:left;
  padding: 10px;
}
</style>
